'use strict';

/**
 * Get JSON data from a data-attribute
 *
 * @param el the DOM element that contains the data attribute
 * @param name the name of the data-attriute (without data- prefix)
 * @param defaultValue the default value
 *
 * @returns the parsed data
 */
module.exports = {
    getJSONData: function (el, name, defaultValue) {
        let result = defaultValue;
        const strData = el.dataset[name];
        if (strData) {
            try {
                const data = JSON.parse(strData);
                if (Array.isArray(data)) {
                    result = data;
                } else {
                    result = {
                        ...defaultValue,
                        ...data,
                    };
                }
            } catch (ignore) {
                // ignore
            }
        }
        return result;
    }
}
