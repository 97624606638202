'use strict';

var scrollAnimate = require('./scrollAnimate');

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearFormErrors(parentSelector) {
    $(parentSelector).find('.form-control').removeClass('is-invalid').removeClass('is-valid');
    $('.error-message').hide();
}

function loadFormErrors(parentSelector, fieldErrors, scrollDisable) { // eslint-disable-line
    // Display error messages and highlight form fields with errors.
    if (!fieldErrors) {
        return;
    }

    $.each(fieldErrors, function (attr) {
        $('*[name=' + attr + ']', parentSelector)
            .addClass('is-invalid')
            .closest('.form-group').find('.invalid-feedback')
            .html(fieldErrors[attr]);
    });
    
    // Animate to top of form that has errors
    if (!scrollDisable) {
        scrollAnimate($(parentSelector));
    }
}

function invalidateField($formGroup, errorMsg) {
    $formGroup.find('.form-control').addClass('is-invalid');

    if (!!errorMsg) {
        $formGroup.find('.invalid-feedback').empty().text(errorMsg);
    }
}

function validateFields($container) {
    var valid = true;

    $container.find('.form-control').each(function () {
        var $input = $(this);
        var $formGroup = $input.closest('.form-group');

        var value = $input.val();
        var pattern = $input.attr('pattern');

        var isChecked = $input.attr('type') === 'checkbox' ? $input.prop('checked') : true;
        var isRequired = $input.prop('required') || $formGroup.hasClass('required');

        var missingErrorMsg = $input.data('missing-error');
        var patternMismatchErrorMsg = $input.data('pattern-mismatch');

        if (isRequired && (!value || !isChecked)) {
            valid = false;
            invalidateField($formGroup, missingErrorMsg);
            return;
        }

        if (isRequired && !!patternMismatchErrorMsg && !!pattern && !(new RegExp(pattern).test(value))) {
            valid = false;
            invalidateField($formGroup, patternMismatchErrorMsg);
            return;
        }

        $input.removeClass('is-invalid');
    });

    var $firstInvalidElement = $container.find('.form-control.is-invalid').first();

    if ($firstInvalidElement.length) {
        scrollAnimate($firstInvalidElement, 100);
    }

    return valid;
}

function validateFormAfterResponse(formElement, payload) {
    // clear form validation first
    clearFormErrors(formElement);
    $('.alert', formElement).remove();

    if (typeof payload === 'object' && payload.fields) {
        Object.keys(payload.fields).forEach(function (key) {
            if (payload.fields[key]) {
                var $formGroup = $(formElement).find('[name="' + key + '"]').closest('.form-group');
                var $feedbackElement = $formGroup.find('.invalid-feedback');

                if ($feedbackElement.length > 0) {
                    if (Array.isArray(payload[key])) {
                        $feedbackElement.html(payload.fields[key].join('<br/>'));
                    } else {
                        $feedbackElement.html(payload.fields[key]);
                    }
                    $formGroup.find('.form-control').addClass('is-invalid');
                }
            }
        });
    }
    if (payload && payload.error) {
        var form = $(formElement).prop('tagName') === 'FORM'
            ? $(formElement)
            : $(formElement).closest('form');

        form.prepend('<div class="alert alert-danger" role="alert">'
            + payload.error.join('<br/>') + '</div>');
    }
}

/**
 * 
 * @param {string} alertSelector alert message selector
 * @param {string} msg alert message
 * @param {string} type a type of alert
 * @param {number} showTime a time of the error popin display
 */
function showFormErrorAlert(alertSelector, msg, type, showTime) {
    if ($(alertSelector).length === 0) {
        $(document.body).append('<div class="' + alertSelector.slice(1) + '"></div>');
    }

    var alertViewClass = 'alert-' + type;

    $(alertSelector).append(
        '<div class="alert ' + alertViewClass + ' text-center"'
        + ' role="alert">'
        + msg + '</div>'
    );

    setTimeout(function () {
        $(alertSelector).remove();
    }, showTime);
}

module.exports = {
    clearFormErrors: clearFormErrors,
    validateFormAfterResponse: validateFormAfterResponse,
    loadFormErrors: loadFormErrors,
    validateFields: validateFields,
    showFormErrorAlert: showFormErrorAlert,
    invalidateField: invalidateField
};
