'use strict';

module.exports = {
    initializeSlickSlider: function () {
        $(document.body).on('slick-slider:init', function () {
            $('.slick-slider:not(.slick-initialized)').slick();
        });
        
        $(document).ready(function () {
            $(document.body).trigger('slick-slider:init');
        });
    },
    setTileMaxHeight: function () {
        var selector = '.slick-slide > div > div';

        var tilesBodyHeight = [];

        $(this).each(function () {
            var $slider = $(this);

            var $slides = $slider.find(selector);

            $slides.css('height', '');

            $slider.find(selector).each(function () {
                tilesBodyHeight.push($(this).height());
            });
        
            var maxHeight = Math.max.apply(Math, tilesBodyHeight);
        
            $slider.find(selector).each(function(){
                $(this).height(maxHeight); 
            });
        });
    }
};
