'use strict';

const Cookies = require('../util/cookies');
const { getDataLayer, trimAllWhiteSpaces, pushClickEvent } = require('../util/gtmUtils');

/** Sub-modules */
//const navigation = require('./navigation');

/** Selectors */
const selectors = {
    video: 'video-js',
    social: '.social-links-wrap .social-link',
    footer: '#footercontent',
    footerExternalLink: '.footer-link.external',
    footerInternalLink: '.footer-link.internal',
    footerPaymentBanner: '.payment-methods-wrap .footer-link',
    changeLocale: '.locale-change-popup',
    addToCartBtn: '.add-to-cart',
    checkoutBtn: '.checkout-btn',
    generalLink: '.js-tracking-link',
    generalButton: '.js-tracking-button',
    recommendationEvent: 'recommendations:init',
    productImpressions: 'data-impressions',
}

/** GTM Video click
function videoHandler() {
    $(document).on('click', selectors.video, function (e) {
        const dataLayer = getDataLayer();
        const target = e.currentTarget;

        try {
            const videoData = {
                event: 'event',
                category: 'video',
                action: 'play',
                label: target.getAttribute('data-video-id'),
                platform: 'Brightcove',
                player_id: target.getAttribute('data-player'),
                player_version: '',
                widget_name: 'Brightcove Video Player',
                event_id: 1
            };

            dataLayer.push(videoData);
        } catch (e) {
            return;
        }
    });
}
*/
/** Footer clicks */

/**
function footerHandler() {
    const EVENT_ID = '3';
    const CATEGORY = 'footer';

    $(document).on('click', `${selectors.footer} ${selectors.social}`, function (e) {
        const $target = $(e.currentTarget);
        const socialImage = $target.find('img');
        const label = trimAllWhiteSpaces(socialImage.attr('alt'));
        const url = $target.attr('href');

        pushClickEvent(CATEGORY, EVENT_ID, label, url);
    });

    $(document).on('click', `${selectors.footerExternalLink}, ${selectors.footerInternalLink}`, function (e) {
        const $target = $(e.currentTarget);
        const label = trimAllWhiteSpaces($target.text());
        const url = $target.attr('href');

        pushClickEvent(CATEGORY, EVENT_ID, label, url);
    });

    $(document).on('click', `${selectors.footerPaymentBanner}`, function (e) {
        const $target = $(e.currentTarget);
        const label = 'payments';
        const url = $target.attr('href');

        pushClickEvent(CATEGORY, EVENT_ID, label, url);
    });
}
*/
/** Locale selection popup */
/**
function changeLocalePopup() {
    $(document).on('click', `${selectors.changeLocale} button`, function (e) {
        const target = e.currentTarget;
        const dataLayer = getDataLayer();

        try {
            const changeLocaleData = {
                'event': 'event',
                'category': 'language',
                'action': 'click',
                'label': trimAllWhiteSpaces(target.innerText),
                'event_id': '5'
            };

            dataLayer.push(changeLocaleData);
        } catch {
            return;
        }
    });
}
*/

/** Button clicks
function buttonClick() {
    const EVENT_ID = '22';
    const CATEGORY = 'button';

    $(document).on('click', `${selectors.addToCartBtn}`, function(e) {
        const $button = $(e.currentTarget);
        const buttonLabel = $button.find('.add-to-cart-text').text();
        const label = trimAllWhiteSpaces(buttonLabel);
        const url = $('.add-to-cart-url').val();

        pushClickEvent(CATEGORY, EVENT_ID, label, url);
    });

    $(document).on('click', `${selectors.checkoutBtn}`, function(e) {
        const $link = $(e.currentTarget);
        const linkLabel = $link.find('.button__label').text();
        const label = trimAllWhiteSpaces(linkLabel);
        const url = $link.attr('href');

        pushClickEvent(CATEGORY, EVENT_ID, label, url);
    });

    $(document).on('click', 'button[type=submit]', function(e) {
        const $button = $(e.currentTarget);
        let buttonLabel = $button.find('.button__label').text();

        if (buttonLabel === '') {
            buttonLabel = $button.text();
        }
        const label = trimAllWhiteSpaces(buttonLabel);
        const url = origin + $button.closest('form').attr('action');

        pushClickEvent(CATEGORY, EVENT_ID, label, url);
    });

    $('.container').on('click', `${selectors.generalButton}`, function(e) {
        const $button = $(e.currentTarget);
        const buttonLabel = $button.text();
        const label = trimAllWhiteSpaces(buttonLabel);
        let btnHref = $button.attr('href');
        let url = origin + btnHref;

        if (!btnHref) {
            url = $button.attr('data-url');
        }

        pushClickEvent(CATEGORY, EVENT_ID, label, url);
    });
}

function linkClick() {
    const EVENT_ID = '10';
    const CATEGORY = 'link';

    $(document).on('click', `${selectors.generalLink}`, function(e) {
        const $link = $(e.currentTarget);
        let linkLabel = $link.find('.button__label').text();

        if (linkLabel === '') {
            linkLabel = $link.text();
        }
        const label = trimAllWhiteSpaces(linkLabel);
        const url = $link.attr('href');

        pushClickEvent(CATEGORY, EVENT_ID, label, url);
    });
}
*/
function productImpressions() {
    $('body').on(selectors.recommendationEvent, function (e) {
        const $target = $(e.currentTarget);
        const $impressionsData = $target.find(`[${selectors.productImpressions}]`);

        if ($impressionsData.length) {
            const dataLayer = getDataLayer();

            try {
                const productImpression = JSON.parse($impressionsData.attr(selectors.productImpressions));
                dataLayer.push(productImpression);
            } catch {
                return;
            }
        }
    });
}

function getGACookieValue() {
    if (window.userInfo) {
        window.userInfo.google_cookie = Cookies.get('_ga');
    }
}

module.exports = {
    //...navigation,
    //videoHandler,
    //footerHandler,
    //changeLocalePopup,
    //buttonClick,
    //linkClick,
    getGACookieValue,
    productImpressions
};
