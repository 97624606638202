'use strict';

var base = require('../product/base');
var focusHelper = require('base/components/focus');
var formHelpers = require('../components/formHelpers');
var scrollAnimate = require('../components/scrollAnimate');
var setTileMaxHeight = require('../components/carousel').setTileMaxHeight;

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (!data.valid.error) {
        $('.checkout-btn').removeClass('disabled');
        return;
    }

    if (data.valid.message) {
        var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
            'fade show" role="alert">' +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
            '<span aria-hidden="true">&times;</span>' +
            '</button>' + data.valid.message + '</div>';

        $('.cart-error').append(errorHtml);
    } else {
        $('.cart').empty().append('<div class="row"> ' +
            '<div class="col-12 text-center"> ' +
            '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
            '</div> ' +
            '</div>'
        );
        $('.minicart .popover').empty();
        $(document.body).trigger('minicart:close');
    }

    $('.checkout-btn').addClass('disabled');
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.header-funnel-articles').removeClass('d-none').empty().text(data.arcticlesCountMsg);

    $('.coupons-and-promos').each(function () {
        $(this).empty().append(data.totals.discountsHtml);
    });

    if(data.totals && data.totals.promotionSavingsHtml) {
        $('.promotion-savings').empty().append(data.totals.promotionSavingsHtml);
        $('.promotion-savings').removeClass('d-none').addClass('d-flex');
    } else {
        $('.promotion-savings').removeClass('d-flex').addClass('d-none');
    }
    $('.shipping-cost').empty().append(data.totals.shippingPriceHtml);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.sub-total-before-discount').empty().append(data.totals.merchandizeTotalPrice);
    $(document.body).trigger('count:update', data);

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    data.items.forEach(function (item) {
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        }
        if (item.priceTotal && item.priceTotal.renderedPrice) {
            $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        }
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $('.availability-' + lineItem.UUID).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach(function (message) {
                messages += '<p class="line-item-attributes">' + message + '</p>';
            });
        }

        if (lineItem.availability.inStockDate) {
            messages += '<p class="line-item-attributes line-item-instock-date">'
                + lineItem.availability.inStockDate
                + '</p>';
        }
    }

    $('.availability-' + lineItem.UUID).html(messages);
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    var lineItem = findItem(data.cartModel.items, function (item) {
        return item.UUID === uuid;
    });

    if (lineItem.variationAttributes) {
        var colorAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'color';
        });

        if (colorAttr) {
            var colorSelector = '.Color-' + uuid;
            var newColor = 'Color: ' + colorAttr.displayValue;
            $(colorSelector).text(newColor);
        }

        var sizeAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'size';
        });

        if (sizeAttr) {
            var sizeSelector = '.Size-' + uuid;
            var newSize = 'Size: ' + sizeAttr.displayValue;
            $(sizeSelector).text(newSize);
        }

        var imageSelector = '.card.product-info.uuid-' + uuid + ' .item-image > img';
        $(imageSelector).attr('src', lineItem.images.small[0].url);
        $(imageSelector).attr('alt', lineItem.images.small[0].alt);
        $(imageSelector).attr('title', lineItem.images.small[0].title);
    }

    if (lineItem.options && lineItem.options.length) {
        var option = lineItem.options[0];
        var optSelector = '.lineItem-options-values[data-option-id="' + option.optionId + '"]';
        $(optSelector).data('value-id', option.selectedValueId);
        $(optSelector + ' .line-item-attributes').text(option.displayName);
    }

    var qtySelector = '.quantity[data-uuid="' + uuid + '"]';
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $('.remove-product[data-uuid="' + uuid + '"]').data('pid', data.newProductId);

    var priceSelector = '.line-item-price-' + uuid + ' .sales .value';
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        var listPriceSelector = '.line-item-price-' + uuid + ' .list .value';
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">'
        + '        <img src="/on/demandware.static/Sites-MG-Site/-/default/dw1452c6a8/images/close.svg"/>'
        + '        </span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $(document.body).append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

function getSelectedSamplesCount($samplesContainer) {
    return $samplesContainer.find('.select-sample-checkbox:checked').length;
}

function removeProductLineItem(uuid) {
    var $currentPli = $('.uuid-' + uuid);

    if ($currentPli.closest('li').length) {
        $currentPli.closest('li').remove();
    } else {
        $currentPli.remove();
    }
}

module.exports = function () {
    $(document.body).on('cart:toggleCheckoutContinueModal', function (e, args) {
        if (args.canSelectMoreBonusItems) {
            $('.checkout-continue-modal').removeClass('d-none');
            $('.checkout-continue-redirect').addClass('d-none');

            return;
        }
        
        $('.checkout-continue-modal').addClass('d-none');
        $('.checkout-continue-redirect').removeClass('d-none');
    });

    $(document.body).on('basket:updateTotals', function (e, args) {
        var basket = args.basket;

        updateCartTotals(basket);
        updateApproachingDiscounts(basket.approachingDiscounts);
        validateBasket(basket);

        $(document.body).trigger('cart:toggleCheckoutContinueModal', { canSelectMoreBonusItems: basket.canSelectMoreBonusItems });
        $(document.body).trigger('basket:updateBonusLineItems', { $container: $('.bonus-product-cards'), html: basket.bonusLineItemsHTML });
        $(document.body).trigger('basket:updateBonusLineItems', { $container: $('.samples-section'), html: basket.samplesSectionHTML });
    });

    $(document.body).on('basket:updateBonusLineItems', function (e, args) {
        var $container = args.$container;

        if (!args.html) {
            $container.removeClass('active');

            setTimeout(function () {
                $container.empty();
            }, 200);
        } else {
            $container.addClass('active').empty().html(args.html);
        }
    });

    $(document.body).on('click', '.remove-product', function (e) {
        e.preventDefault();

        var $btn = $(this);

        var actionUrl = $btn.data('action');
        var productID = $btn.data('pid');
        var productName = $btn.data('name');
        var uuid = $btn.data('uuid');
        
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $(document.body).on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $(document.body).on('focusout', '.coupon-code-field', function () {
        formHelpers.clearFormErrors($(this).closest('form'));
    });

    $(document.body).on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.quantityTotal === 0) {
                    if ($('.cart-page').length) {
                        window.location.reload();
                    } else {
                        $('.minicart .popover').empty();
                        $('.minicart .popover').removeClass('show');
                        $(document.body).removeClass('modal-open');
                        $('html').removeClass('veiled');

                        $(document.body).trigger('count:update', data.basket);
                        
                        $('.header-funnel-articles').addClass('d-none').empty();
                    }
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        data.toBeDeletedUUIDs.forEach(function (uuid) {
                            removeProductLineItem(uuid);
                        });
                    }

                    removeProductLineItem(uuid);
                    $(document.body).trigger('basket:updateTotals', { basket: data.basket });

                    if (data.checkoutButtonsHTML) {
                        $('.checkout-buttons').empty().append(data.checkoutButtonsHTML);
                    }
                }
                
                $(document.body).trigger('cart:update');

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $(document.body).on('quantity:update', function (_, args) {
        var $quantitySelector = args.$quantitySelector;

        var $addBtn = $quantitySelector.find('button[data-selector="add"]');
        var $subtractBtn = $quantitySelector.find('button[data-selector="subtract"]');

        var minOrderQuantity = parseInt($quantitySelector.data('min-quantity'), 10);
        var maxOrderQuantity = parseInt($quantitySelector.data('max-quantity'), 10);

        var productID = $quantitySelector.data('pid');
        var url = $quantitySelector.attr('action');
        var uuid = $quantitySelector.data('uuid');

        var quantity = args.quantity;

        if (!quantity || quantity < minOrderQuantity) {
            quantity = minOrderQuantity;
        } else if (quantity > maxOrderQuantity) {
            quantity = maxOrderQuantity;
        }

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $quantitySelector.closest('.funnel-product-card').spinner().start();

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    var $input = $quantitySelector.find('input');
                    
                    $input.val(quantity.toFixed(0));
                    $input.removeAttr('data-focusout-enable');

                    var basket = data.basket;

                    if (quantity > minOrderQuantity) {
                        $subtractBtn.removeAttr('disabled');
                    } else if (quantity === minOrderQuantity) {
                        $subtractBtn.attr('disabled', true);
                    }

                    if (quantity >= maxOrderQuantity) {
                        $addBtn.attr('disabled', true);
                    } else {
                        $addBtn.removeAttr('disabled');
                    }

                    $(document.body).trigger('basket:updateTotals', { basket: basket });
                    updateAvailability(basket, uuid);

                    $(document.body).trigger('cart:update');
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                }
            },
            complete: function () {
                $quantitySelector.closest('.funnel-product-card').spinner().stop();
            }
        });
    });

    $(document.body).on('input', '.quantity-select-funnel input', function (e) {
        e.preventDefault();

        var $input = $(this);
        var value = e.target.value.replaceAll(/[^\d]/g, '');

        if (!value) {
            $input.val('');
            return;
        }

        var $quantitySelector = $input.closest('form');

        var quantity = parseInt(value, 10);
        var minOrderQuantity = parseInt($quantitySelector.data('min-quantity'), 10);

        if (!quantity || quantity < minOrderQuantity) {
            $input.val(minOrderQuantity);
            return;
        }

        $input.val(quantity);
    });

    $(document.body).on('focusin', '.quantity-select-funnel input', function () {
        $(this).attr('data-focusout-enable', true);
    });

    $(document.body).on('focusout', '.quantity-select-funnel input', function (e) {
        e.preventDefault();

        var $input = $(this);
        var $quantitySelector = $input.closest('form');

        if (!$input.attr('data-focusout-enable')) {
            return;
        }

        var minOrderQuantity = parseInt($quantitySelector.data('min-quantity'), 10);
        var quantity = parseInt(e.target.value.replaceAll(/[^\d]/g, ''), 10);

        if (!quantity) {
            quantity = minOrderQuantity;
        }

        $(document.body).trigger('quantity:update', { $quantitySelector: $quantitySelector, quantity: quantity });
    });

    $(document.body).on('submit', '.quantity-select-funnel', function (e) {
        e.preventDefault();

        var $quantitySelector = $(this);

        var quantity = parseInt($quantitySelector.find('input').val(), 10);

        $(document.body).trigger('quantity:update', { $quantitySelector: $quantitySelector, quantity: quantity });
    });

    $(document.body).on('click', '.quantity-select-funnel button[data-selector]', function (e) {
        e.stopPropagation();
        var $btn = $(this);
        var $quantitySelector = $btn.closest('.quantity-select-funnel');

        var quantity = parseInt($quantitySelector.find('input').val(), 10);

        if ($btn.data('selector') === 'add') {
            quantity++;
        } else {
            quantity--;
        }

        $(document.body).trigger('quantity:update', { $quantitySelector: $quantitySelector, quantity: quantity });
    });

    $(document.body).on('submit', '.promo-code-form', function (e) {
        e.preventDefault();

        var $form = $(this);

        if (!formHelpers.validateFields($form)) {
            return;
        }

        $form.spinner().start();

        var $couponCodeField = $form.find('.coupon-code-field');
        var $formControl = $form.find('.form-control');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $formControl.addClass('is-invalid');
                    $formControl.attr('aria-describedby', 'invalidCouponCode');

                    formHelpers.invalidateField($formControl.closest('.form-group'), data.errorMessage);
                } else {
                    $(document.body).trigger('basket:updateTotals', { basket: data });
                    $('.promo-code-form').addClass('d-none');
                }

                $couponCodeField.val('');
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                }
            },
            complete: function () {
                $form.spinner().stop();
            }
        });
        return false;
    });

    $(document.body).on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $(document.body).on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('#coupon-uuid-' + uuid).remove();
                $(document.body).trigger('basket:updateTotals', { basket: data });
                $.spinner().stop();
                
                if (!$('.coupon-container').length) {
                    $('.promo-code-form').removeClass('d-none');
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $(document.body).on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $(document.body).on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $(document.body).removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $(document.body).on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $(document.body).on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $(document.body).on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $(document.body).on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $(document.body).on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $(document.body).on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $(document.body).on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $(document.body).on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $(document.body).on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $(document.body).on('click', '.color-attribute', function() {
        var $colorTxt = $('.color');
        var $colorAttr = $(this);
        var $colorBtn = $colorAttr.find('.color-value');

        if ($colorBtn.hasClass('unselectable')) {
            return;
        }

        var $wrapper = $colorBtn.closest('.product-add-to-cart');
        var $addToCart = $wrapper.find($wrapper.data('selector')).find('.add-to-cart');

        if(!$colorBtn.hasClass('selected')) {
            $colorTxt.empty().html($colorAttr.attr('aria-describedby'));
            $addToCart.html($('.ready-to-order').val());
            $addToCart.removeClass('product-add-to-cart-disabled');
        } else {
            $colorTxt.empty().html($('#color-attr-default-name').val());
            $addToCart.html($('.not-ready-to-order').val());
            $addToCart.addClass('product-add-to-cart-disabled');
        }
    }); 

     //Change the attribute name dysplayed according to attribute selected
     $(document.body).on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $(document.body).trigger('basket:updateTotals', { basket: data.cartModel });
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        removeProductLineItem(data.uuidToBeDeleted);
                    }

                    $(document.body).trigger('cart:update');

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $(document.body).on('click', '.cart-back', function() {
        window.history.back();
    });

    $(document.body).on('click', '.checkout-continue-modal', function (e) {
        e.preventDefault();
        var isApplepay = $(this).attr('data-applepay');

        var $confirmModal = $('#confirmCheckoutContinueModal');

        $confirmModal.attr('data-continue-applepay', isApplepay || '');

        $confirmModal.fadeIn(500, function () {
            $('.cart-page .checkout-continue-modal').addClass('launched-modal');
            $(document.body).addClass('modal-open');
            $confirmModal.addClass('show');
        });
    });

    $(document.body).on('click', '#confirmCheckoutContinueModalButton', function (e) {
        var $confirmModal = $('#confirmCheckoutContinueModal');

        var isApplepay = !!$confirmModal.attr('data-continue-applepay');

        if (isApplepay) {
            e.preventDefault();

            var $closeModalBtn = $confirmModal.find('.close');
            var $applePayCartBtn = $('#applepayCartButton');
            
            $closeModalBtn.click();
            $applePayCartBtn.click();
        }
    });

    $(document.body).on('click', '#confirmCheckoutContinueModal .close', function (e) {
        var $confirmModal = $('#confirmCheckoutContinueModal');

        e.preventDefault();
        $confirmModal.fadeOut(500, function (){
            $('.cart-page .checkout-continue-modal').removeClass('launched-modal');
            $(document.body).removeClass('modal-open');
            $confirmModal.removeClass('show');
        });
    });

    $(document.body).on('click', '#confirmCheckoutContinueModal .select-bonus-products', function (e) {
        e.preventDefault();

        if (!!$(this).data('cancel')) {
            scrollAnimate($('.samples-product-cards'));
        }
        
        $(this).closest('#confirmCheckoutContinueModal').find('.close').trigger('click');
    });

    $(document.body).on('click', '.cart-slider-arrows button', function () {
        var $slider = $(this).closest('.cart-slider-container').find('.slick-slider');
        
        $slider.slick($(this).data('swipe-direction'));
    });

    $(document.body).on('afterChange', '.cart-slider-container .slick-slider', function (event, slick, currentSlide) {
        var $lastSlide = slick.$slides.last();
        var $nextArrowBtn = $lastSlide.closest('.cart-slider-container').find('.cart-slider-arrows button[data-swipe-direction=slickNext]');

        if ($lastSlide.hasClass('slick-active')) {
            $nextArrowBtn.prop('disabled', true);
            return;
        }

        $nextArrowBtn.prop('disabled', false);
    });

    $(document.body).on('click', '.select-sample', function () {
        var $checkbox = $(this).prev('input');

        var $productCard = $checkbox.closest('.funnel-product-card');
        var $container = $checkbox.closest('.samples-product-slider');

        var $submitSamplesSelectionBtn = $('.submit-samples-selection button');

        if ($checkbox.is(':checked')) {
            $productCard.removeAttr('data-sample-selected');
            $checkbox.prop('checked', false);
            return;
        }

        if (getSelectedSamplesCount($container) < parseInt($container.data('max-samples-count'), 10)) {
            $checkbox.prop('checked', true);
            $productCard.attr('data-sample-selected', true);
            $submitSamplesSelectionBtn.prop('disabled', false);
        }
    });

    $(document.body).on('submit', '.select-samples-form', function (e) {
        e.preventDefault();
        var $form = $(this);

        var $readyToOrderBonusProducts = $form.find('.funnel-product-card[data-sample-selected="true"]');

        var queryString = '?pids=';

        var pidsObject = {
            bonusProducts: []
        };

        $.each($readyToOrderBonusProducts, function () {
            var option = null;
            
            if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                option = {};
                option.optionId = $(this).data('optionid');
                option.productId = $(this).data('pid');
                option.selectedValueId = $(this).data('option-selected-value');
            }

            pidsObject.bonusProducts.push({
                pid: $(this).data('pid'),
                qty: 1,
                options: [option]
            });
        });

        pidsObject.totalQty = $readyToOrderBonusProducts.length;

        queryString += JSON.stringify(pidsObject);
        queryString = queryString + '&uuid=' + $form.data('uuid');
        queryString = queryString + '&pliuuid=' + $form.data('pliuuid');

        $form.spinner().start();

        $.ajax({
            url: $form.attr('action') + queryString,
            method: 'POST',
            success: function (data) {
                $form.spinner().stop();

                if (data.error) {
                    if ($('.add-to-cart-messages').length === 0) {
                        $(document.body).append('<div class="add-to-cart-messages"></div>');
                    }

                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-danger add-to-basket-alert text-center"'
                        + ' role="alert">'
                        + data.errorMessage + '</div>'
                    );
                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                    }, 3000);
                    return;
                }

                if ($('.add-to-cart-messages').length === 0) {
                    $(document.body).append('<div class="add-to-cart-messages"></div>');
                }
                
                $('.add-to-cart-messages').append(
                    '<div class="alert alert-success add-to-basket-alert text-center"'
                    + ' role="alert">'
                    + data.msgSuccess + '</div>'
                );

                setTimeout(function () {
                    $('.add-to-basket-alert').remove();
                }, 1500);

                $(document.body).trigger('cart:toggleCheckoutContinueModal', { canSelectMoreBonusItems: !data.selectedSamplesHTML });

                if (!!data.selectedSamplesHTML) {
                    $('.selected-samples-container').empty().append(data.selectedSamplesHTML);
                    $('.select-samples-form').removeClass('active');

                    return;
                }

                $('.submit-samples-selection button').prop('disabled', true);
            },
            error: function () {
                $form.spinner().stop();
            }
        });
    });

    $(document.body).on('click', '.modify-samples-selection', function () {
        $(this).closest('.samples-product-cards').find('.select-samples-form').addClass('active');
    });

    $(document.body).on('click', '.line-item-description-long > span', function () {
        var $toggleBtn = $(this);
        var $description = $toggleBtn.closest('.line-item-description-long').find('p');

        var newDescriptionText = $description.data('new-description');
        var oldDescriptionText = $description.data('old-description');

        var newBtnLabel = $toggleBtn.data('new-button-label');
        var oldBtnLabel = $toggleBtn.data('old-button-label');

        $description.data('new-description', oldDescriptionText);
        $description.data('old-description', newDescriptionText);
        $description.empty().text(newDescriptionText);

        $toggleBtn.data('new-button-label', oldBtnLabel);
        $toggleBtn.data('old-button-label', newBtnLabel);
        $toggleBtn.empty().text(newBtnLabel);

        var $slider = $toggleBtn.closest('.slick-slider');

        setTileMaxHeight.call($slider);
    });

    $(document.body).on('init', '.cart-page .slick-slider', function (_, slick) {
        setTileMaxHeight.call(slick.$slider);
    });

    $(document.body).on('click', '.best-seller-cards .add-to-cart', function () {
        var $btn = $(this);

        $btn.empty().text($btn.data('added-msg'));
        $btn.prev().removeClass('d-none');
        $btn.addClass('disabled');
    });

    $(document.body).on('checkout:disableButton', function (e, button) {
        $(button).prop('disabled', true);
    });

    $(document.body).on('checkout:enableButton', function (e, button) {
        $(button).prop('disabled', false);
    });

    $(document.body).on('change', '.checkout-checkbox input', function () {
        if ($(this).is(':checked')) {
            $(this).removeClass('is-invalid');
        }
    });

    $(document.body).on('click', '.place-order-b2b', function (e) {
        var $tcCheckbox = $('.terms-condition #checkout_read_terms');

        if(!$tcCheckbox.is(':checked')){
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            
            $tcCheckbox.addClass('is-invalid');
            scrollAnimate($tcCheckbox, 100);
            return;
        }

        $(document.body).trigger('checkout:disableButton', '.place-order-b2b');

        $.ajax({
            url: $(this).data('action'),
            method: 'POST',
            success: function (data) {
                // enable the placeOrder button here
                if (data.error) {
                    $(document.body).trigger('checkout:enableButton', '.place-order-b2b');
                    formHelpers.showFormErrorAlert('.b2b-validate-cart-messages', data.errorMessage, 'danger', 3000);

                    if (data.cartError) {
                        window.location.href = data.redirectUrl;
                    } else {

                    }
                } else {
                    var continueUrl = data.continueUrl;
                    var urlParams = {
                        ID: data.orderID,
                        token: data.orderToken
                    };

                    continueUrl += (continueUrl.indexOf('?') !== -1 ? '&' : '?') +
                        Object.keys(urlParams).map(function (key) {
                            return key + '=' + encodeURIComponent(urlParams[key]);
                        }).join('&');

                    window.location.href = continueUrl;
                }
            },
            error: function (err) {
                $(document.body).trigger('checkout:enableButton', '.next-step-button');

                if (err.responseJSON && err.responseJSON.redirectUrl) {

                    window.location.href = err.responseJSON.redirectUrl;
                }
            }
        });
    });

    base.selectAttribute();
    base.colorAttribute();
    base.toggleBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
