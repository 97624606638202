'use strict';

module.exports = function () {
    $(document.body).on('mouseenter focusin', '.info-icon', function () {
        $(this).find('.tooltip').removeClass('d-none');
    });

    $(document.body).on('mouseleave focusout', '.info-icon', function () {
        $(this).find('.tooltip').addClass('d-none');
    });
};
