'use strict';

var Cookies = require('../util/cookies');

function checkUrlPartExistence(urlPart, url, urlPartsCount) {
    if (urlPartsCount === 1 && urlPart.indexOf('?') + 1 === urlPart.length) {
        urlPart = urlPart.replace('?', '');
    }

    return urlPart && url.indexOf(urlPart) > -1;
}

function isRestrictedUrl(url) {
    if (!url) {
        return;
    }

    var urlParts = url.split('*').filter(function (part) {
        return !!part;
    });

    var urlPartsCount = urlParts.length;

    var isAbsUrl = checkUrlPartExistence('http://', url) || checkUrlPartExistence('https://', url);

    var currentAbsUrl = window.location.href;

    if (isAbsUrl) {
        return checkUrlPartExistence(urlParts[0], currentAbsUrl);
    }

    if (!checkUrlPartExistence(urlParts[0], currentAbsUrl, urlPartsCount)) {
        return false;
    } 

    var searchParams = window.location.search;

    for (var i = 0; i < urlPartsCount; i++) {
        var urlPart = urlParts[i];

        var isRestricted = checkUrlPartExistence(urlPart, window.location.pathname, urlPartsCount);

        if (urlPart.indexOf('?') > -1) {
            urlPart = urlPart.slice(urlPart.indexOf('?'));
        }

        if (isRestricted || (searchParams && checkUrlPartExistence(urlPart, searchParams, urlPart.length))) {
            return true;
        }
    }

    return false;
}

function displayMessage(msg) {
    $("body").trigger("popin:newsletter:close");

    if ($(".newsletter-subscription-error-message").length === 0) {
        $("body").append(
            '<div class="newsletter-subscription-error-message"></div>'
        );
    }

    $(".newsletter-subscription-error-message").append(
        '<div class="alert alert-success alert-danger text-center" role="alert">' +
            msg +
            "</div>"
    );

    setTimeout(function () {
        $(".newsletter-subscription-error-message").remove();
    }, 3000);
}

module.exports = {
    showPopIn: function () {
        $(document).ready(function () {
            const NEWSLETTER_COOKIE_NAME = 'newsletterHidePopIn';

            var $newsletterPopInWrapper = $('.newsletter-pop-in-wrapper');

            if ($newsletterPopInWrapper.length === 0) {
                Cookies.remove(NEWSLETTER_COOKIE_NAME);
                return;
            }

            var disabledPagesUrlRules = $newsletterPopInWrapper.data('page-filter-url-rules');

            if (!!disabledPagesUrlRules) {
                var isDisabled = false;

                disabledPagesUrlRules = disabledPagesUrlRules.split(';');
            
                disabledPagesUrlRules.forEach(function (url) {
                    if (isRestrictedUrl(url)) {
                        isDisabled = true;
                    }
                });

                if (isDisabled) {
                    return;
                }
            }

            var newsletterHidePopIn = parseInt(Cookies.get(NEWSLETTER_COOKIE_NAME), 10);

            var newsletterPopInDisplayCount = parseInt($newsletterPopInWrapper.data('display-count'), 10);
            var newsletterDisplayByPage = parseInt($newsletterPopInWrapper.data('display-by-page'), 10);

            if (isNaN(newsletterHidePopIn)) {
                Cookies.set(NEWSLETTER_COOKIE_NAME, 1);
                newsletterHidePopIn = 1;
            } else if (newsletterHidePopIn < (newsletterDisplayByPage * newsletterPopInDisplayCount)) {
                Cookies.set(NEWSLETTER_COOKIE_NAME, ++newsletterHidePopIn);
            } else {
                return;
            }

            if (newsletterHidePopIn % newsletterDisplayByPage !== 0) {
                return;
            }

            var newsletterPopInImageUrl = $newsletterPopInWrapper.data('image-url');

            if (!!newsletterPopInImageUrl) {
                $('.newsletter-pop-in-wrapper .newsletter-popin-image > img').attr('src', newsletterPopInImageUrl);
            }

            var newsletterPopInShowTimeValue = parseFloat($newsletterPopInWrapper.data('show-time'), 10);
            
            setTimeout(function () {
                $('.newsletter-container').removeClass('d-none');
            }, newsletterPopInShowTimeValue);

            setTimeout(function () {
                $('.newsletter-popin-container').addClass('newsletter-popin-active');
            }, newsletterPopInShowTimeValue + 200);

            if ($(window).width() < 768) {
                $('#newsletter-signup-disclaimer').prop('checked', true);
            }
        });
    },
    closePopIn: function () {
        $('body').on('popin:newsletter:close', function () {
            $('.newsletter-popin-container').removeClass('newsletter-popin-active');
            $('.newsletter-form-submitted').removeClass('newsletter-popin-active');

            setTimeout(function () {
                $('.newsletter-container').addClass('d-none');
            }, 300);
        });

        $('body').on('click', '.newsletter-close, .newsletter-overlay, .newsletter-continue', function () {
            $('body').trigger('popin:newsletter:close');
        });
    },
    submitPopInForm: function () {
        $('body').on('submit', '.newsletter-form', function (e) {
            e.preventDefault();

            var $form = $(this);

            var $emailInput = $form.find('input[name="MERGE0"]');
            var $error = $form.find('.invalid-feedback');
            
            var email = $emailInput.val();
            var pattern = new RegExp($emailInput.attr('pattern'));

            if (!pattern.test(email)) {
                $error.text($emailInput.data('pattern-mismatch'));
                $emailInput.addClass('is-invalid');
                return;
            }

            $error.text('');
            $emailInput.removeClass('is-invalid');

            var $newsletterConsent = $('#newsletter-signup-disclaimer');
            if ($newsletterConsent.is(':checked')) {
                $(document.body).trigger('mailchimp:subscribe', {
                    email: email,
                    applyPromoCode: false,
                    pageSource: 'Popin',
                    callback: {
                        success: function (data) {
                            let responseMsg = data.responseMsg;
                            let alreadySubscribedMsg = $(".newsletter-pop-in-wrapper").data("already-subscribed");
                            let errorMessage= $('.newsletter-pop-in-wrapper').data('error'); 
                            const alreadySubscribed = "already subscribed";
                            const alreadySubscribed_DE = "bereits angemeldet";
                            if (data.success) {
                                if(responseMsg.indexOf(alreadySubscribed_DE) !== -1 || responseMsg.indexOf(alreadySubscribed) !== -1) {                           
                                    displayMessage(alreadySubscribedMsg);
                                } else {
                                    $('.newsletter-popin-container').addClass('d-none');
                                    $('.newsletter-form-submitted').css('height', 'auto');
                                    $('.newsletter-form-submitted').addClass('newsletter-popin-active');
                                }
                            } else {
                                displayMessage(errorMessage);
                            }
                        },
                        complete: function () {
                        }
                    }
                });
            }
        });
    }
};
