'use strict';

module.exports = {
    subscribeToMailChimp: function () {
        $(document.body).on('mailchimp:subscribe', function (_, args) {
            var url = $('[data-subscribe-to-mailchimp]').data('subscribe-to-mailchimp');

            if (!!url) {
                var applyPromoCode = !!args.applyPromoCode ? '1' : '';
                var callback = {
                    success: !!args.callback && !!args.callback.success ? args.callback.success : function () {},
                    error: !!args.callback && !!args.callback.error ? args.callback.error : function () {},
                    complete: !!args.callback && !!args.callback.complete ? args.callback.complete : function () {}
                };

                $.ajax({
                    url: url,
                    method: 'POST',
                    data: {
                        email: args.email,
                        applyPromoCode: applyPromoCode,
                        pageSource: args.pageSource,
                        birthday: args.birthday,
                        fname: args.fname,
                        lname: args.lname
                    },
                    success: function (data) {
                        if (data.success) {
                            callback.success(data);
                        }

                        if (data.error) {
                            callback.error(data.errorMsg);
                        }
                    },
                    error: function (err) {
                        callback.error(err);
                    },
                    complete: function () {
                        callback.complete();
                    }
                })
            }
        });
    }
};
