'use strict';

window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./components/mailchimp'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/validateFormInputs'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('./components/toolTip'));
    processInclude(require('./product/wishlistHeart'));
    processInclude(require('./popUp'));
    processInclude(require('./components/newsletterPopIn'));
});

$(document).ready(function () {
    setTimeout(function () {
      if (!!$('#cookie-fix-dev-environment').val()) {
        $('#onetrust-consent-sdk').remove();
      }
    }, 800);
});

require('./thirdParty/bootstrap');
require('base/components/spinner');

$(document).ready(function () {
    const $overlay = $(".search.mobile-menu #searchOverlay");
    const $inputField = $(".navbar-toggleable-md .menu-search-content-wrapper .search-field");
    const $content = $(".menu-group");
    const $closeBtn = $(".search.mobile-menu #searchOverlay #closeButton");
    const $overlayinput = $(".navbar-toggleable-md .search-content-wrapper .search-field");
    let $suggestionWrapper = $(".search.mobile-menu #searchOverlay .suggestions-wrapper");

    $inputField.on("click", function () {
        $overlay.addClass("active");
        $overlayinput.focus();
        if (!$overlayinput.val()) {
          $content.removeClass("shifted shifted-50 shifted-extra");
      }
    });

    function adjustContentShift() {
      const $productSuggestions = $('.search.mobile-menu #searchOverlay .product-suggestions');
      const $suggestedCategories = $('.search.mobile-menu #searchOverlay #search-results');

      const isFocused = $overlayinput.is(":focus");
      const hasSuggestions = $suggestionWrapper.children().length > 0;
      const hasProductSuggestions = $productSuggestions.children().length > 0;
      const hasSuggestedCategories = $suggestedCategories.children().length > 1;

      if (!isFocused) {
        $content.removeClass("shifted shifted-50 shifted-extra");
        return;
      }

      if (!hasSuggestions) {
          $content.removeClass("shifted shifted-50 shifted-extra");
          return;
      }

      if (hasSuggestions && hasProductSuggestions) {
          $content.removeClass("shifted shifted-50");
          if (hasSuggestedCategories) {
              $content.addClass("shifted-extra");
          } else {
              $content.addClass("shifted");
          }
      } else {
          $content.removeClass("shifted").addClass("shifted-50");
      }
    }

    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === "childList") {
                adjustContentShift();
            }
        });
    });

    if ($suggestionWrapper[0] instanceof Node) {
      observer.observe($suggestionWrapper[0], { childList: true });
    }

    $overlayinput.on('blur', () => {
      setTimeout(() => {
          if (!$overlayinput.val()) {
              $content.removeClass("shifted shifted-50 shifted-extra");
          }
      }, 200);
  });

    $closeBtn.on("click", function () {
        $overlay.removeClass("active");
        $content.removeClass("shifted shifted-50 shifted-extra");
    });

    adjustContentShift();

});

$('.navbar-header .menu-search-content-wrapper .openBtn').on('click', function () {
  $('.navbar-header #searchOverlay').css('display', 'block');
});

$('#closeButton').on('click', function () {
  document.getElementById('searchOverlay').style.display = 'none';
});

var lastScrollTop = 0;

$(window).scroll(function() {
  var scroll = $(window).scrollTop();
  var mobile = $(window).width() <= 768;
   //>=, not <=
  if (scroll > 0) {
      //clearHeader, not clearheader - caps H
    if (!$("header").hasClass("sticky")) {
      $("header").addClass("sticky");
      if (!mobile) {
          $("nav .main-menu div:first-child").addClass('border-top-0');
          $("#maincontent").css('margin-top',$('header').height());
      }
    }
  } else {
    if ($("header").hasClass("sticky")) {
      $("header").removeClass("sticky");
      if (!mobile) {
          $("nav .main-menu div:first-child").removeClass('border-top-0');
          $("#maincontent").removeAttr('style');
      }
    }
  }

  if (scroll > lastScrollTop && scroll > 10 && mobile) {
    $('.header-banner').removeClass('active');
  } else if(mobile) {
    $('.header-banner').addClass('active');
  }

  lastScrollTop = scroll;
});

require('gtm_mg/gtm');

window.lazySizesConfig = window.lazySizesConfig || {};
//page is optimized for fast onload event
lazySizesConfig.loadMode = 1;

require('lazysizes');
require('lazysizes/plugins/unveilhooks/ls.unveilhooks');

require('slick-carousel');
