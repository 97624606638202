'use strict';

/** Pages */
const general = require('../pages/general');
const product = require('../pages/product');
const login = require('../pages/login');
const contact = require('../pages/contact');
const search = require('../pages/search');
const experience = require('../pages/experience');

module.exports = {
  ...general,
  ...product,
  ...login,
  ...contact,
  ...search,
  ...experience
};

