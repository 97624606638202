'use strict';

const gtmUtils = require('../util/gtmUtils');
/** Selectors */
const selectors = {
    searchFilter: 'search:filter',
    searchSort: 'search:sort',
    searchPharmacyButton: '.pharmacy-search__submit',
    searchPharmacyInput: '.pharmacy-search__input'
}

function searchFilter() {
    $(document).on(selectors.searchFilter, (e) => {
        const dataLayer = gtmUtils.getDataLayer();
        const $currentTarget = $(e.target);
        const parent = $currentTarget.closest('[data-attribute-type]');

        if (parent && parent.length) {
            const attributeType = parent.attr('data-attribute-type');
            const attributeValue = parent.attr('data-attribute-value');

            try {
                const payload = {
                    event: 'event',
                    category: 'filter',
                    action: attributeType,
                    label: attributeValue,
                    event_id: '13',
                }
                dataLayer.push(payload);
            } catch {
                return;
            }
        }
    });
}

function searchSort() {
    $(document).on(selectors.searchSort, (e) => {
        const dataLayer = gtmUtils.getDataLayer();
        const $currentTarget = $(e.target);
        const selectedFilter = gtmUtils.trimAllWhiteSpaces($currentTarget.text());

        try {
            const payload = {
                event: 'event',
                category: 'filter',
                action: 'sort',
                label: selectedFilter,
                event_id: '14',
            }
            dataLayer.push(payload);
        } catch {
            return;
        }
    });
}

function searchPharmacy() {
    $(document).on('click', selectors.searchPharmacyButton, (e) => {
        const dataLayer = gtmUtils.getDataLayer();
        const $currentTarget = $(e.target);

        try {
            var payload =    {
                event: 'custom.event',
                eventDetails: {
                    eventCategory:'Search Pharmacy',
                    eventAction:'Search Pharmacy - Request',
                    eventLabel: $currentTarget.parent().find(selectors.searchPharmacyInput).val(),
                    eventInteraction:false
                }
            }
            dataLayer.push(payload);
        } catch {
            return;
        }
    });
}

module.exports = {
    searchFilter,
    searchSort,
    searchPharmacy
}
