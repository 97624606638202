'use strict';

const gtmUtils = require('../util/gtmUtils');
/** Selectors */
const selectors = {
    contactForm: 'form.contact-us'
}

function contactFormSubmit() {
    $(selectors.contactForm).submit(function (e) {
        const dataLayer = gtmUtils.getDataLayer();

        try {
            const payload = {
                event: "custom.event",
                eventDetails: {
                    eventCategory: "Contact",
                    eventAction: "Contact - Form Request",
                    eventLabel: 'Valid',
                    eventInteraction:false
                }
            }
            dataLayer.push(payload);
        } catch {
            return;
        }
    });
}

module.exports = {
    contactFormSubmit
};
