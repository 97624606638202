/**
 * @description Initializes the necessary interactions for Google Analytics tracking
 */
const processInclude = require('base/util');
const analyticsBase = require('./googleAnalytics/googleAnalytics');
const analyticsEvents = require('./googleAnalytics/clientSideEvents');

$(document).ready(() => {
  processInclude(analyticsBase);
  processInclude(analyticsEvents);
});
