/**
 * Cookie component class
 */
module.exports = class Cookies {
    /**
     * Get the value of specified cookie
     * @param {String} name The cookie name
     */
    static get(name) {
        let cname = name + "=";
        let ca = document.cookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(cname) != -1) return c.substring(cname.length, c.length);
        }

        return '';
    }

    /**
     * Check if cookie exists
     * @param {String} name The cookie name
     */
    static has(name) {
        return this.get(name) !== '';
    }

    /**
     * Set the specified cookie name with value and expiration days
     * @param {String} name The cookie name
     * @param {String} value The cookie value
     * @param {Number} cdays The expiration days
     */
    static set(name, value, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toGMTString();
        let cookieString = name + "=" + value + "; " + expires + "; path=/; secure";

        document.cookie = cookieString;
    }

    /**
     * Remove the specified cookie
     * @param {String} name The cookie name
     */
    static remove(name) {
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;secure;';
    }
};
