'use strict';

module.exports = function (element, customOffset = 0) {
    var position = element && element.length ? element.offset().top - customOffset : 0;

    $('html, body').animate({
        scrollTop: position - $('header').height()
    }, 500);
    if (!element) {
        $('.logo-home').focus();
    }
};
