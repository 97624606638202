'use strict'
    
    let hidePopUp = window.sessionStorage.getItem('popUp_hide');

    $(document).ready(function(){
        if(hidePopUp !== '1'){
            setTimeout(function() {
                $('#popUpModal').modal();
            }, 2000);
        }
    })

    $(document).mouseup(function(e){
        let container = $('.pop-up-container');
        let oneTrustBox = $('#onetrust-consent-sdk');
        let closeCross = $('.pop-up-container .close-cross img');
        let containerButton = $('.pop-up-container a.button-component');
        if( hidePopUp !== '1'){
            // if the target of the click isn't the container nor a descendant of the container nor oneTrust PopUp nor Button inside container
            if (closeCross.is(e.target) || ( containerButton.is(e.target) && containerButton.has(e.target).length === 0 ) || (!container.hasClass('d-none') && !container.is(e.target)  && container.has(e.target).length === 0 && !oneTrustBox.is(e.target) && oneTrustBox.has(e.target).length === 0)){
                window.sessionStorage.setItem('popUp_hide', '1');
            }
        }
    });
