'use strict';


var scrollAnimate = require('./scrollAnimate');
let successMessage= $('.popWiseNewsletter').data('success'); 
let errorMessage= $('.popWiseNewsletter').data('error'); 
let doubleOptInMessage= $('.popWiseNewsletter').data('optin'); 
let secoundDoubleOptInMessage = $('.popWiseNewsletter').data('second-optin');
let thirdDoubleOptInMessage = $('.popWiseNewsletter').data('third-optin');
let alreadySubscribedMsg = $('.popWiseNewsletter').data('already-subscribed');
let locale = $('.popWiseNewsletter').data('locale');
const alreadySubscribed = "already subscribed";
const alreadySubscribed_DE = "bereits angemeldet";

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
/* removed email signup popup in ticket MGDES-978
function displayMessage(data) {
    $.spinner().stop();
    if (data.result === 'success') {
        if($('.email-signup-message').length === 0){
        $('body').append(
            '<div class="email-signup-message"></div>'
        );
        
            $('.email-signup-message').append('<div class="subscribe-success-message-box"><p class="signup-title">' + successMessage + '</p><p>' + data.msg + '</p></div>');
            setTimeout(function(){
                $('.email-signup-message').remove();
            },7000);
        }

    } 
}
*/

// Turn the given MailChimp form into an ajax version of it.
// If resultElement is given, the subscribe result is set as html to
// that element.
function ajaxMailChimpForm($form, $resultElement){

    $form.submit(function(e) {
        e.preventDefault();
        if (!isValidEmail($form)) {
            var error =  "A valid email address must be provided.";
            $resultElement.html(error);
            $resultElement.addClass('color-mgberry');
        } else {
            $resultElement.css("color", "black");
            $resultElement.html("Subscribing...");
            submitSubscribeForm($form, $resultElement);
        }
    });
}

// Validate the email address in the form
function isValidEmail($form) {
    // If email is empty, show error message.
    // contains just one @
    var email = $form.find("input[type='email']").val();
    if (!email || !email.length) {
        return false;
    } else if (email.indexOf("@") == -1) {
        return false;
    }

    return true;
}

// Submit the form with an ajax/jsonp request.

function submitSubscribeForm($form, $resultElement) {
    var email = $form.find('input[type="email"]').val();
    var isChecked = $form.find('input[type="checkbox"]').is(':checked');
    if(isChecked) {
        $(document.body).trigger('mailchimp:subscribe', {
            email: email,
            applyPromoCode: false,
            pageSource: 'Footer',
            callback: {
                success: function(data){
                    console.log(data);
                    if (!data.success) {
                        $resultElement.addClass('color-mgberry');
                        $resultElement.html(errorMessage);
                    } else {
                        $resultElement.css("color", "black");
                        if(data.responseMsg.indexOf(alreadySubscribed_DE) !== -1 || data.responseMsg.indexOf(alreadySubscribed) !== -1) {
                            $resultElement.html(alreadySubscribedMsg);
                        } else {
                            $('.newsletter-container').removeClass('d-none');
                            
                            setTimeout(function () {
                                $('.newsletter-popin-container').addClass('d-none');
                                $('.newsletter-form-submitted').css('height', 'auto');
                                $('.newsletter-form-submitted').addClass('newsletter-popin-active');
                            }, 300);

                            if(locale === 'de_DE'){
                            $resultElement.html(doubleOptInMessage + '</br>' + secoundDoubleOptInMessage +' ' + $('.email[name="MERGE0"]').val() + ' ' + thirdDoubleOptInMessage);
                            // displayMessage(data); - removed email signup popup in ticket MGDES-978
                            } else{
                            $resultElement.html('');
                            }
                        }
                        $form.each(function() {
                            this.reset();
                        });
                    }
                },
                error: function(err){
                    $resultElement.html("Error. Please try again");
                    // $form.find('input.button').attr('disabled','disabled');
                },
                complete: function () {
                    $form.spinner().stop();
                    // location.href = redirectUrl;
                }
            }
        });
        return;
    }
}


module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });



    // creating another handler to prevent ajax jsonp multiple response
    $('.pop-wise-newsletter').on('click','input.button',function(e){
        let formGrandParent = $(this).closest('.popWiseNewsletter');
            if(!$(formGrandParent[0]).find('.OI16').is(':checked')){
                $(formGrandParent).find('.checkbox-label-OI16').addClass('color-mgberry');
            }else{
                $(formGrandParent).find('.checkbox-label-OI16').removeClass('color-mgberry');
            }
            if(!$(formGrandParent[0]).find('.OIDATA').is(':checked')){
                $(formGrandParent).find('.checkbox-label-OIDATA').attr('style','color:#CC5C57 !important');
                $(formGrandParent).find('.checkbox-label-OIDATA a').attr('style','color:#CC5C57 !important');
            }else{
                $(formGrandParent).find('.checkbox-label-OIDATA').removeAttr('style');
                $(formGrandParent).find('.checkbox-label-OIDATA a').removeAttr('style');
                if($(formGrandParent).find('.MERGE0').val() !== ''){
                    ajaxMailChimpForm($(formGrandParent).closest(".popWiseNewsletter"), $(formGrandParent).next(".subscribe-result"));
                    e.stopPropagation();
                }else{
                    e.preventDefault(); 
                }
            }
    });
    
};
