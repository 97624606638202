'use strict';

var cart = require('../cart/cart');

var updateMiniCart = true;
var timer;

module.exports = function () {
    cart();
    
    $(document.body).on('minicart:open', function () {
        var $popover = $('.minicart .popover');

        $popover.addClass('d-md-block');

        setTimeout(function () {
            $popover.addClass('show');
        }, 200);
    });

    $(document.body).on('minicart:close', function () {
        var $popover = $('.minicart .popover');
        
        $popover.removeClass('show');

        setTimeout(function () {
            $popover.removeClass('d-md-block');
        }, 200);
    });

    $(document.body).on('count:update', function (_, response) {
        if (!!response) {
            $('.minicart .minicart-quantity').empty().text(response.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': response.minicartCountOfItems,
                title: response.minicartCountOfItems
            });
        }
    });

    $(document.body).on('mouseenter focusin touchstart', '.minicart', function () {
        var mobile = $(document).width() <= 768;

        if (mobile) {
            return;
        }

        var $minicart = $(this);
        
        var url = $minicart.data('action-url');
        var count = parseInt($minicart.find('.minicart-quantity').text(), 10);
        window.clearTimeout(timer);
        timer = 0;
        
        var $popover = $minicart.find('.popover');

        if (!$popover.hasClass('d-md-block')) {
            $popover.removeClass('show');
        }

        if (count !== 0 && !$popover.hasClass('show')) {
            $(document.body).trigger('minicart:open');

            if (!updateMiniCart) {
                return;
            }

            $popover.spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (data) {
                    $popover.empty();
                    $popover.append(data);
                    updateMiniCart = false;
                    $popover.spinner().stop();
                }
            });
        }
    });
    
    $(document.body).on('touchstart click', function (e) {
        if (!$('.minicart').has(e.target).length && !$(document.body).hasClass('modal-open')) {
            $(document.body).trigger('minicart:close');
        }
    });

    $(document.body).on('mouseleave focusout', '.minicart', function (event) {
        if ((event.type === 'focusout' && $(this).has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $(document.body).hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
    });

    $(document.body).on('mouseenter', '.minicart .popover', function () {
        window.clearTimeout(timer);
        timer = 0;
    });

    $(document.body).on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $(document.body).on('cart:update', function () {
        updateMiniCart = true;
    });

    $(document.body).on('click', '.minicart-close-button', function () {
        $(document.body).trigger('minicart:close');
    });
};
