'use strict';

const { trimAllWhiteSpaces, pushClickEvent, onPromoClick, onPromoView } = require('../util/gtmUtils');

const selectors = {
    cta: '.js-tracking-c-button',
    ctaLabel: '.button__label',
    promotionAttribute: 'data-promo',
    visiblePromotions: '[data-promo]:visible',
    contentBlockTileLink: '.c-content-block-tile a'
}

function getPromotions() {
    return $(selectors.visiblePromotions).toArray();
}

function getPromotionPosition(promotionObj) {
    return getPromotions().indexOf(promotionObj) + 1;
}
/**
function handleComponentLinkEvents() {
    const EVENT_ID = '23';
    const CATEGORY = 'link';

    $(document).on('click', `${selectors.contentBlockTileLink}`, function(e) {
        const $link = $(e.currentTarget);
        const promoTrackingData = $link.attr(selectors.promotionAttribute);

        if (promoTrackingData) {
            const trackingData = JSON.parse(promoTrackingData);
            const promoPosition = getPromotionPosition(e.currentTarget);

            onPromoClick({ ...trackingData, position: promoPosition });
        } else {
            const linkLabel = $link.attr('data-label');
            const label = trimAllWhiteSpaces(linkLabel);
            const url = $link.attr('href');

            pushClickEvent(CATEGORY, EVENT_ID, label, url);
        }
    });
}

function handleComponentButtonEvents() {
    const EVENT_ID = '22';
    const CATEGORY = 'button';

    $(document).on('click', `${selectors.cta}`, function(e) {
        const $button = $(e.currentTarget);
        const promoTrackingData = $button.attr(selectors.promotionAttribute);

        if (promoTrackingData) {
            const trackingData = JSON.parse(promoTrackingData);
            const promoPosition = getPromotionPosition(e.currentTarget);

            onPromoClick({ ...trackingData, position: promoPosition });
        } else {
            const buttonLabel = $button.find(selectors.ctaLabel).text();
            const label = trimAllWhiteSpaces(buttonLabel);
            const url = $button.attr('href');

            pushClickEvent(CATEGORY, EVENT_ID, label, url);
        }
    });
}
*/

function promoImpressionEvents() {
    const promotionContent = getPromotions();

    if (promotionContent.length) {
        const impressions = promotionContent.map((item, index) => {
            const promotionJson = $(item).attr(selectors.promotionAttribute);
            const promotion = JSON.parse(promotionJson);

            return {
                id: trimAllWhiteSpaces(promotion.id),
                name: trimAllWhiteSpaces(promotion.name) ,
                creative: trimAllWhiteSpaces(promotion.creative),
                position: index + 1
            };
        });

        onPromoView(impressions);
    }
}


module.exports = {
   // handleComponentLinkEvents,
   // handleComponentButtonEvents,
    promoImpressionEvents
}
