'use strict';

const gtmUtils = require('../util/gtmUtils');
/** Selectors */
const selectors = {
    login: '#login .link',
    register: '#register .link'
}

function loginClick() {
    $(document).on('click', selectors.login, (e) => {
        const dataLayer = gtmUtils.getDataLayer();
        const currentTarget = $(e.currentTarget);

        try {
            const payload = {
                event: 'event',
                category: 'login',
                action: 'click',
                label: gtmUtils.trimAllWhiteSpaces(currentTarget.text()),
                event_id: '7',
                destination_url: currentTarget.prop('href'),
            }
            dataLayer.push(payload);
        } catch {
            return;
        }
    });
}

function registerClick() {
    $(document).on('click', selectors.register, (e) => {
        const dataLayer = gtmUtils.getDataLayer();
        const currentTarget = $(e.currentTarget);

        try {
            const payload = {
                event: 'event',
                category: 'register',
                action: 'click',
                label: gtmUtils.trimAllWhiteSpaces(currentTarget.text()),
                event_id: '8',
                destination_url: currentTarget.prop('href'),
            }
            dataLayer.push(payload);
        } catch {
            return;
        }
    });
}

module.exports = {
    loginClick,
    registerClick
};
