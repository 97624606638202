'use strict';

const { getJSONData } = require('../util/domUtils');
const { getDataLayer, getCurrency } = require('../util/gtmUtils');

const getProductList = (trigger) => {
    const listContainers = {
        'shop_match_with': '#matchWith',
        'shop_customers_also_viewed': '#customersAlsoViewed',
        'shop_cart_recently_viewed': '#cartRecentlyViewed',
        'shop_pdp': '[data-action="Product-Show"]',
        'shop_category': '[data-action="Search-Show"][data-querystring*="cgid="]',
        'shop_search_results': '[data-action="Search-Show"][data-querystring*="q="]',
        'shop_search_other': '[data-action="Search-Show"]',
        'shop_product_group': '.c-product-group'
    };

    return Object.keys(listContainers).find(listName => trigger.closest(listContainers[listName])) || 'other';
};

const getProductObject = (trigger) => {
    const trackingEl = trigger.closest('[data-tracking]');

    if (trackingEl) {
        return getJSONData(trackingEl, 'tracking', null);
    }

    return null;
};

function productClick () {
    ['click', 'auxclick'].forEach((evt) => {
        $(document).on(evt, '.product-tile, .filled-product-tile__link, .vertical-product-tile__link', (e) => {
            const target = e.currentTarget;
            const dataLayer = getDataLayer();
            const productObj = getProductObject(target);
            const productPosition = $(target).closest('[data-position]').attr('data-position');
            const link = target.tagName === 'A' ? target : $(target).find('a')[0];

            // Only a standard left click needs to be aborted and handled with a callback;
            // Scroll/Middle button clicks open a new tab, so they will be tracked by the current tab; same for Ctrl + Click
            // Right clicks must open a context menu, so no way to track those
            const standardClick = evt === 'click' && e.button === 0 && !e.ctrlClick;
            const auxilliaryClick = evt === 'auxclick' && e.button >= 2;

            if (auxilliaryClick) {
                // Auxilliary buttons usually don't interact with links, so no need to track them
                return;
            }

            if (productObj) {
                try {
                    if (productPosition) {
                        productObj.position = productPosition;
                    }

                    const payload = {
                        event: 'eec.productClick',
                        ecommerce: {
                            currencyCode: getCurrency(),
                            click: {
                                actionField: {
                                    list: getProductList(target),
                                },
                                products: [{
                                    ...productObj
                                }],
                            },
                        },
                    };

                    if (standardClick) {
                        payload.eventCallback = () => { window.location.href = link.href; };
                        payload.eventTimeout = 300;
                    }

                    dataLayer.push(payload);
                } catch {
                    return;
                }

                if (standardClick) {
                    e.preventDefault();

                    setTimeout(() => {
                        window.location.href = link.href;
                    }, 1000);
                }
            }
        });
    });
}

function variationSelect () {
    $(document).on('click', '.product__attributes .attribute a', (e) => {
        const dataLayer = getDataLayer();
        const $target = $(e.currentTarget);
        const attributeID = $target.attr('data-attr-id');
        const attributeValue = $target.attr('data-attr-value');

        if (attributeID) {
            try {
                const payload = {
                    event: 'event',
                    category: `change_${attributeID}`,
                    action: 'click',
                    label: attributeValue && attributeValue.toLowerCase()
                }

                if (attributeID === 'color') {
                    payload.event_id = '17'
                } else if (attributeID === 'size') {
                    payload.event_id = '18'
                }

                dataLayer.push(payload);
            } catch {
                return;
            }
        }
    });
}

module.exports = {
    productClick,
    variationSelect
};
