'use strict';

const EMPTY_SPACE_REGEX = new RegExp(/\s/g);

function getDataLayer() {
    let dataLayer = [];

    if (window.googleAnalytics && window[window.googleAnalytics.DATA_LAYER_NAME]) {
        dataLayer = window[window.googleAnalytics.DATA_LAYER_NAME];
    }

    return dataLayer;
};

function getCurrency() {
    const localeInfo = JSON.parse($('[data-current-locale]').attr('data-current-locale'));
    return localeInfo.currencyCode;
};

function trimAllWhiteSpaces(str) {
    return str.trim().replace(EMPTY_SPACE_REGEX, '_').toLowerCase();
}

function pushClickEvent(category, eventId, label, url) {
    const dataLayer = getDataLayer();

    try {
        const payload = {
            event: 'custom.event',
            eventDetails: {
                eventCategory: category,
                eventAction: 'click',
                eventLabel: label,
                eventId: eventId,
                destination_url: url,
                eventInteraction:false
            }
        };
        dataLayer.push(payload);
    } catch {
        return;
    }
}

function onPromoClick(promoObj) {
    const dataLayer = getDataLayer();

    try {
        const payload = {
            event: 'promotionClick',
            ecommerce: {
                promoClick: {
                    promotions: [promoObj]
                }
            }
        };

        dataLayer.push(payload);
    } catch {
        return;
    }
}

function onPromoView(promotions) {
    const dataLayer = getDataLayer();

    try {
        const payload = {
            event: 'promoImpression',
            ecommerce: {
                promoView: {
                    promotions: promotions
                }
            }
        };

        dataLayer.push(payload);
    } catch {
        return;
    }
}

module.exports = {
    getDataLayer,
    getCurrency,
    trimAllWhiteSpaces,
    pushClickEvent,
    onPromoClick,
    onPromoView
};
