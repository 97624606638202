'use strict';

var formHelpers = require('../components/formHelpers');
var base = require('base/checkout/address');
var scrollAnimate = require('../components/scrollAnimate');

function populateAddressSummary(parentSelector, address) {
    $.each(address, function (attr) {
        var val = address[attr];

        if (typeof val === 'object' && !!val) {
            $('.' + attr, parentSelector).text(val.value || '');
        } else {
            $('.' + attr, parentSelector).text(val || '');
        }
    });
}

function updateCheckoutAddressSummary($form, address) {
    Object.keys(address).forEach(function (key) {
        var field = address[key];
        var value = null;

        if (!!field && typeof(field) === 'object') {
            value = field.htmlValue;

            if (key === 'country') {
                value = $form.find('select option[value=' + value + ']').text().trim();
            }
        } else {
            value = field;
        }

        var $addressEl = $form.find('.checkout-address-' + key);

        if (!!value && $addressEl.length) {
            $addressEl.removeClass('d-none');
            $addressEl.empty().text(value);
            return;
        }

        $addressEl.addClass('d-none');
    });
}

module.exports = {
    methods: {
        populateAddressSummary: populateAddressSummary,
        updateCheckoutAddressSummary: updateCheckoutAddressSummary,
        optionValueForAddress: base.methods.optionValueForAddress,
        getAddressFieldsFromUI: base.methods.getAddressFieldsFromUI
    },

    cancelAddressModifying: function() {
        $(document.body).on('form:cancelModifying', function (e, args) {
            var $addressFields = args.cancelBtn.closest('.checkout-address-block');
            
            $(document.body).trigger('form:toggleCheckoutFields', { mode: 'disable', fields: $addressFields });

            formHelpers.clearFormErrors($addressFields);

            $addressFields.find('.form-control').each(function () {
                var $field = $(this);

                var prevValue = $field.data('previous-value');
                
                $field.val(prevValue);

                if ($field.is('select')) {
                    var $customSelect = $field.closest('.checkout-select');

                    var $selectedEl = $customSelect.find('li[data-option-value=' + prevValue + ']');

                    $customSelect.find('li').removeClass('selected');
                    $selectedEl.addClass('selected');

                    $customSelect.find('p.selected-value').empty().text($selectedEl.text());
                }
            });
        });

        $(document.body).on('click', '.checkout-form-cancel', function () {
            scrollAnimate();
            $(document.body).trigger('form:cancelModifying', { cancelBtn: $(this) });
        });
    },

    addNewAddress: function () {
        $(document.body).on('click', '.btn-add-new', function () {
            var $el = $(this);
            var $form = $el.closest('form');

            var $addressFields = $form.find('.checkout-address-block');

            $(document.body).trigger('form:toggleCheckoutFields', { mode: 'enable', fields: $addressFields });
    
            formHelpers.clearFormErrors($form);

            $addressFields.find('.form-control').val('');
            
            var $countrySelect = $addressFields.find('.country-select');

            var countryDisplayName = $countrySelect.find('option').first().text();
            var countryValue = $countrySelect.find('option').first().attr('value');

            if ($countrySelect.find('option:disabled').length) {
                countryDisplayName = $countrySelect.find('option:disabled').text();
                countryValue = '';
            }

            $countrySelect.find('.selected-value').text(countryDisplayName);
            $countrySelect.find('select').val(countryValue);

            var $salutationSelect = $addressFields.find('.salutation-select');

            var salutationDisplayName = $salutationSelect.find('option').first().text();
            var salutationValue = $salutationSelect.find('option').first().attr('value');

            if ($salutationSelect.find('option:disabled').length) {
                salutationDisplayName = $salutationSelect.find('option:disabled').text();
                salutationValue = '';
            }

            $salutationSelect.find('.selected-value').text(salutationDisplayName);
            $salutationSelect.find('select').val(salutationValue);

            $addressFields.find('.checkout-form-cancel').removeClass('d-none');
            
            $form.attr('data-address-mode', 'new');
            $form.attr('data-loqate-address-populated', '');
        });
    },

    showDetails: function () {
        $(document.body).on('click', '.btn-show-details', function () {
            var $form = $(this).closest('form');

            $(document.body).trigger('form:toggleCheckoutFields', { mode: 'enable', fields: $form.find('.checkout-address-block') });

            $form.find('#shippingAddressUseAsBillingAddress').prop('checked', false);
    
            $form.find('.checkout-address-block .checkout-form-cancel').removeClass('d-none');

            $form.attr('data-address-mode', 'details');
            $form.attr('data-loqate-address-populated', '');
            $form.find('.multi-ship-address-actions').removeClass('d-none');
            $form.find('.multi-ship-action-buttons .col-12.btn-save-multi-ship').addClass('d-none');
        });
    },
    toggleCheckoutAddressFields: function () {
        $(document.body).on('form:toggleCheckoutFields', function (_, args) {
            var $fields = args.fields;

            if (args.mode === 'enable') {
                $fields.addClass('active');
                $fields.closest('form').find('#shippingAddressUseAsBillingAddress').prop('checked', false);
            } else if (args.mode === 'disable') {
                $fields.removeClass('active');
            }
        });
    }
};
