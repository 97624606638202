'use strict';

var shippingHelpers = require('../checkout/shipping');

function shouldSkipValidateOnInput($input, ev) {
    return ev.type === 'input' && $input.attr('data-first-value-entered') !== 'true' && !$input.hasClass('is-invalid');
}

function validateRequired($input) {
    if (!$input.closest('.form-group').hasClass('required')) {
        return true;
    }

    if ($input.val() === '') {
        $input.addClass('is-invalid');
        return false;
    }

    $input.removeClass('is-invalid');
    return true;
}

function validatePostalCodeInput($input) {
    var errorMsg = '';

    var $invalidFeedback = $input.closest('.form-group').find('.invalid-feedback');
    $input.removeClass('is-invalid').removeClass('is-valid');
    
    var $submitBtn = $input.closest('form').find('.custom-form-validation');

    var requiredValidation = validateRequired($input);

    if (!requiredValidation) {
        errorMsg = $input.data('missing-error');
    }

    var formatValidation = shippingHelpers.methods.validatePostalCode($input);

    if (!formatValidation) {
        errorMsg = $input.data('pattern-mismatch');
    }

    if (errorMsg) {
        $input.addClass('is-invalid').removeClass('is-valid');
        $invalidFeedback.empty().text(errorMsg);

        return false;
    }

    $input.removeClass('is-invalid').addClass('is-valid');
    $invalidFeedback.empty();
    $submitBtn.prop('disabled', null);
    return true;
}

module.exports = function () {
    $(document.body).on('change', 'select[name$=_country]', function (e) {
        var $input = $(this).closest('form').find('input[name$=_postalCode]');

        validatePostalCodeInput($input);
    });

    $(document.body).on('change focusout input', 'input[name$=_postalCode]', function (ev) {
        var $input = $(this);

        if (shouldSkipValidateOnInput($input, ev)) {
            return false;
        }

        if (ev.type !== 'input' && $input.attr('data-first-value-entered') !== 'true') {
            $input.attr('data-first-value-entered', true);
        }

        validatePostalCodeInput($input);
    });
};
